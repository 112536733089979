<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <data-table :items="getWarehouses" :edit="edit" @edit="handleEdit" @item="handleItem"  >
                </data-table>
            </v-col>
            <v-btn class="item-btn" @click="openDialog">{{ $t('buttons.addNew') }}</v-btn>
            <router-link to="/warehouse-items">
                <v-btn class="items-btn">{{ $t('buttons.items') }}</v-btn>
            </router-link>
            <dialog-component v-model="dialog" :dialog="dialog" :item="item" :edit="edit"></dialog-component>
            
        </v-row>

    </div>
</template>

<script>
import DataTable from "../components/WarehouseComponents/DataTable.vue";
import DialogComponent from "../components/WarehouseComponents/DialogComponent.vue";
import { useWarehouseStore } from "./../store/WarehouseStore";
export default {
    components: {
        DataTable,
        DialogComponent
    },
    data() {
        return {
            warehouseStore: null,
            dialog: false,
            edit: false,
            item:{},
            // headers: [
            //     { text: "WarehouseName", value: "name" },
            //     { text: "Branch", value: "branchName" },
            //     { text: "Type", value: "isMaintenance" },
            // ],
        }
    },
    
    created() {
        this.warehouseStore = useWarehouseStore();
        this.warehouseStore.setWarehouses();
    },
    methods: {
        openDialog() {
            this.dialog = true
            this.edit = false
        },
        handleEdit(val){
          this.dialog = val
          this.edit = val
        },
        handleItem(val){
           this.item = val
        }
    },
    computed: {
        getWarehouses() {  
            return this.warehouseStore.getWarehouses;
        },

    }
}
</script>

<style scoped>
.item-btn {
    background-color: #2a8862 !important;
    color: white !important;
    margin-top: 46px;
    /* Adjust margin top as needed */
    margin-left: 20px;
    /* Adjust margin left as needed */
    height: 44px;
    border-radius: 10px;
    width: 145px;
    margin-right: 10px;
}

.items-btn {
    background-color: white !important;
    background: #000000;
    margin-top: 46px;
    /* Adjust margin top as needed */
    margin-left: 20px;
    /* Adjust margin left as needed */
    height: 44px;
    border-radius: 10px;
    width: 145px;
    margin-right: 10px;
    border: 2px solid #2A8862
}
</style>