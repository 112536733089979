<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="translatedHeaders" :items="translatedItems" width="auto">
                  <template v-slot:header="{ props }">
        <thead>
          <tr>
            <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
              <v-text-field
                
                v-model="header.inputValue" hide-details></v-text-field>
            </th>
          </tr>
          <tr>

          </tr>
        </thead>
      </template>
                    <template #item="{ item }">
                        <tr>
                            <td>{{ item.name }}</td>
                            <td>{{ getBranchName(item.branchId) }}</td>
                            <td  v-if="item.isMaintenance == 1"><span class="maintenance">Maintenance</span></td>
                            <td v-else>Normal</td>

                            <td>
                                <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
                            </td>
                            <td>
                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { useWarehouseStore } from "./../../store/WarehouseStore";
import { useBranchesStore } from "./../../store/BranchesStore";
export default {
  name: "DataTable",
  props: {
    items: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    headers: {
      type: Array,
    },
  },
  data() {
    return {
      warehouseStore: null,
      branchStore: null,
    };
  },
  methods: {
    editItem(item) {
      this.$emit("item", item);
      this.$emit("edit", true);
    },
    deleteItem(item) {
      this.warehouseStore.deleteWarehouse(item.id);
    },
    getBranchName(id) {
      const item = this.getBranches.find((item) => item.id === id);
      return item ? item.name : '';
    },
  },
  computed: {
    getBranches() {
      return this.branchStore.getBranches;
    },
    translatedHeaders() {
      return this.$t(`warehouseHeaders`);
    },
    translatedItems() {
      return this.items.filter((item) => {
        
        const name = item.name;
        const branch = this.getBranchName(item.branchId)
        const type = item.isMaintenance == 0 ?'Normal':'Maintenance'
        return (
             name
            .toLowerCase()
            .includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
            branch
            .toLowerCase()
            .includes(this.translatedHeaders[1].inputValue.toLowerCase()) &&
            type
            .toLowerCase()
            .includes(this.translatedHeaders[2].inputValue.toLowerCase()) 
        );
      });
    },
  },
  created() {
    this.warehouseStore = useWarehouseStore();
    this.branchStore = useBranchesStore();
    this.branchStore.setBranches();
  },
};
</script>

<style lang="scss" scoped>
.maintenance {
  width: 123px;
  height: 28px;
  top: 164px;
  left: 683px;
  gap: 0px;
  border-radius: 10px 10px 10px 10px;
  opacity: 0px;
  background-color: #323754;
  color: white;
  padding: 6px;
}
</style>
