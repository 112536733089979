
export default {
  itemsHeaders: [
    { text: "Name", value: "name", inputValue: "" },
    { text: "Item No", value: "itemNo", inputValue: "" },
    { text: "Factory", value: "factoryName", inputValue: "" },
    { text: "Model", value: "model", inputValue: "" },
    { text: "Source", value: "source", inputValue: "" },
    { text: "Cost", value: "cost", inputValue: "" },
    { text: "Min Qty", value: "minQty", inputValue: "" },
  ],
  warehouseHeaders: [
    { text: "Warehouse Name", value: "name", inputValue: "" },
    { text: "Branch", value: "branchName", inputValue: "" },
    { text: "Type", value: "isMaintenance", inputValue: "" }
  ],
  warehouseItemsHeaders: [
    {
      text: "warehouseName",
      value: "warehouseName",
      divider: true,
      inputValue: "",
    },
    { text: "Item No", value: "itemNo", divider: true, inputValue: "" },
    { text: "Item Name", value: "itemName", divider: true, inputValue: "" },
    { text: "Factory", value: "factoryName", divider: true, inputValue: "" },
    { text: "Model", value: "model", divider: true, inputValue: "" },
    { text: "Source", value: "source", divider: true, inputValue: "" },
    { text: "Cost", value: "cost", divider: true, inputValue: "" },
    { text: "minQty", value: "minQty", divider: true, inputValue: "" },
    { text: "Qty", value: "qty", inputValue: "" },
  ],
  transferHeaders: [
    { text: "Item Name", value: "itemName" },
    { text: "Item No", value: "itemNo" },
    { text: "Factory", value: "factory" },
    { text: "Model", value: "model" },
    { text: "Source", value: "source" },
    { text: "Cost", value: "cost" },
    { text: "Min Qty", value: "minQty" },
    { text: "Qty", value: "qty" },
    { text: "Transfer Qty", value: "transferQty" },
  ],
  refillHeaders: [
    { text: "Name", value: "name" },
    { text: "Item No", value: "itemNo" },
    { text: "Factory", value: "factory" },
    { text: "Model", value: "model" },
    { text: "Source", value: "source" },
    { text: "Cost", value: "cost" },
    { text: "Min Qty", value: "minQty" },
    { text: "Qty", value: "qty" },
    { text: "Refill Qty", value: "refillQty" },
  ],
  exportationHeaders: [
    { text: "Name", value: "name" },
    { text: "Item No", value: "itemNo" },
    { text: "Factory", value: "factory" },
    { text: "Model", value: "model" },
    { text: "Source", value: "source" },
    { text: "Cost", value: "cost" },
    { text: "Min Qty", value: "minQty" },
    { text: "Qty", value: "qty" },
    { text: "Export Qty", value: "exportQty" },
  ],

  operationsHeaders: [
    { text: "Warehouse Name", value: "warehouseName", inputValue: "" },
    { text: "Type", value: "type", inputValue: "" },
    { text: "User", value: "userName", inputValue: "" },

    { text: "Date", value: "date", inputValue: "" },

  ],
  operationTabHeaders: [
    { text: "Item Name", value: "itemName" },
    { text: "ItemNo", value: "itemNo" },
    { text: "Qty", value: "qty" },
  ],

  labels: {
    user: "User",
    date: "Date",
    selectWarehouse: "Select Warehouse",
    selectItem: "Select Item",
    sourceWarehouse: "Source Warehouse",
    targetWarehouse: "Target Warehouse",
    refillItems: "Refill Items",
    importExcel: "Import Excel",
    transferItem: "Transfer Item",
    exportationItems: "Exportation Items",
    exportationType: "Exportation Type",
    type: "Type",
    warehouseName: "Warehouse Name",
    itemName: "Item Name",
    newWarehouse: "New Warehouse",
    editWarehouse: "Edit Warehouse",
    newItem: "New Item",
    editItem: "Edit Item",
    operationDetails: "Operation Details",
    itemNo: "Item No",
    factory: "Factory",
    model: "Model",
    source: "Source",
    cost: "cost",
    minQty: "min_qty",
    qty: 'Qty'
  },
  selectLanguage: "Select Language",
  sideBar: {
    Dashboard: "Dashboard",
    Users: "Users",
    Warehouses: "Warehouses",
    WarehouseItems: "Warehouse Items",
    Reports: "Reports",
    Items: "Items",
    Operations: "Operations"
  },
  title: {
    name: "Tech Care Warehouse"
  },
  warehouses: {
    warehouseName: 'warehouseName',
    branch: 'Branch',
    type: 'Type',
    description: "Description",
  },
  buttons: {
    addNew: "Add New",
    importExcel: "Import Excel",
    exportExcel: "Export As Excel",
    transfer: "Transfer",
    inquirey: "Inquirey",
    exportation: "Exportation",
    items: "Items",
    cancel: "Cancel",
    submit: "Submit",
    logout: "Logout"
  },
  tabs: {
    mainData: "Main Data",
    moreData: "More Data"
  },
  exportationTypes: {
    sell: "Sell",
    waste: "Waste",
    other: "Other",
    seller: "Seller",
    buyer: "Buyer",
    note: "Note"
  }
};
