
export default {
  itemsHeaders: [
    { text: "الاسم", value: "name", inputValue: "" },
    { text: "رقم العنصر", value: "itemNo", inputValue: "" },
    { text: "المصنع", value: "factoryName" , inputValue: ""},
    { text: "النموذج", value: "model", inputValue: "" },
    { text: "المصدر", value: "source" , inputValue: ""},
    { text: "الكلفة", value: "cost" , inputValue: ""},
    { text: "الكمية الدنيا", value: "minQty" , inputValue: ""},
    // Add more column definitions as needed
  ],
  warehouseHeaders:[
    { text: "اسم المستودع", value: "name" ,inputValue:""},
    { text: "الفرع", value: "branchName" ,inputValue:"" },
    { text: "النوع", value: "isMaintenance" ,inputValue:"" },
  ],
  warehouseItemsHeaders:[
    { text: "اسم المستودع", value: "warehouseName" ,divider: true,
    inputValue: "" },
    { text:"اسم العنصر",value:"itemName",divider: true,
    inputValue: "",},
    { text:"رقم العنصر",value:"itemNo",divider: true,
    inputValue: "",},
    { text: "المصنع", value: "factory" ,divider: true,
    inputValue: "",},
    { text: "النموذج", value: "model",divider: true,
    inputValue: "", },
    { text: "المصدر", value: "source" ,divider: true,
    inputValue: "",},
    { text: "الكلفة", value: "cost",divider: true,
    inputValue: "", },
    { text: "الكمية الدنيا", value: "minQty" ,divider: true,
    inputValue: "",},
    { text: "الكمية", value: "qty" },

    // Add more column definitions as needed
  ],
  transferHeaders: [
    { text: "اسم المادة", value: "name" },
    { text: "رقم المادة", value: "itemNo" },
    { text: "المصنع", value: "factory" },
    { text: "الموديل", value: "model" },
    { text: "المصدر", value: "source" },
    { text: "الكلفة", value: "cost" },
    { text: "الكمية الدنيا", value: "minQty" },
    { text: "الكمية في المستودع", value: "qty" },
    { text: "كمية النقل", value: "transferQty" },
  ],
  refillHeaders: [
    { text: "اسم المادة", value: "name" },
    { text: "رقم المادة", value: "itemNo" },
    { text: "المصنع", value: "factory" },
    { text: "الموديل", value: "model" },
    { text: "المصدر", value: "source" },
    { text: "الكلفة", value: "cost" },
    { text: "الكمية الدنيا", value: "minQty" },
    { text: "الكمية في المستودع", value: "qty" },
    { text: "كمية التزويد", value: "transferQty" },
  ],
  exportationHeaders: [
    { text: "اسم المادة", value: "name" },
    { text: "رقم المادة", value: "itemNo" },
    { text: "المصنع", value: "factory" },
    { text: "الموديل", value: "model" },
    { text: "المصدر", value: "source" },
    { text: "الكلفة", value: "cost" },
    { text: "الكمية الدنيا", value: "minQty" },
    { text: "الكمية في المستودع", value: "qty" },
    { text: "كمية التخريج", value: "exportationQty" },
  ],
  operationsHeaders:[
    { text: "اسم المستودع", value: "warehouseName" ,inputValue: ""},
    { text: "النوع", value: "type" ,inputValue: ""},
    { text: "المستخدم", value: "userName" ,inputValue: ""},
    { text: "التاريخ", value: "date" ,inputValue: ""},

  ],
  operationTabHeaders:[
    { text: "اسم المادة", value: "itemName" },
    { text: "رقم المادة", value: "itemNo" },
    { text: "الكمية", value: "qty" },
  ],
  
  labels: {
    user:"المستخدم",
    date:"التاريخ",
    selectWarehouse: "اختر مستودع",
    selectItem:"اختر مادة",
    importExcel:"ادخال اكسل",
    transferItem:"نقل مادة",
    refillItems:"مواد بحاجة اعادة تزويد",
    sourceWarehouse:"المستودع المصدر",
    targetWarehouse:"المستودع الهدف",
    warehouseName:"اسم المستودع",
    exportationItems:"تخريج مواد",
    exportationType:"نوع التخريج",
    type:"نوع",
    itemName:"اسم العنصر",
    newWarehouse:"مستودع جديد",
    newItem:"مادة جديدة",
    editWarehouse:"تعديل مستودع",
    editItem:"تعديل مادة",
    operationDetails:"تفاصيل العملية",
    itemNo: "رقم المادة",
    factory: "المصنع",
    model: "الموديل",
    source: "المصدر",
    cost: "الكلفة",
    minQty: "الكمية الدنيا",
    qty:'الكمية'
  },
  sideBar:{
    Dashboard:"لوحة تحكم",
    Users:"المستخدمين",
    Warehouses:"المستودعات",
    WarehouseItems:"مواد المستودعات",
    Reports:"التقارير",
    Items:"المواد",
    Operations:"العمليات"
  },
  tabs:{
    mainData:"المعلومات الرئيسية",
    moreData:"معلومات إضافية"
  },

  title:{
    name:"لوحة تحكم المستودعات"
  },
  selectLanguage:"اختر لغة",
  warehouses:{
    warehouseName:'اسم المستودع',
    branch:'الفرع',
    type:'النوع',
    description:"الوصف",
  },
  buttons:{
    addNew:"أضف جديد",
    importExcel:"إدخال اكسل",
    exportExcel:"تخريج اكسل",
    transfer:"نقل مواد",
    inquirey:"استعلام",
    exportation:"تخريج",
    items:"المواد",
    cancel:"إلغاء",
    submit:"تأكيد",
    logout:"تسجيل خروج"
  },
  exportationTypes:{
    sell:"بيع",
    waste:"هدر",
    other:"غير ذلك",
    seller:"بائع",
    buyer:"المشتري",
    note:"ملاحظات"
  }
};
