      
      <template   >
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" >
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>{{ $t('sideBar.Dashboard') }}</p>
      </sidebar-link>
      <!-- <sidebar-link to="/users">
        <md-icon>person</md-icon>
        <p>{{ $t('sideBar.Users') }}</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link> -->
  
      <sidebar-link to="/warehouse">
        <md-icon>warehouse</md-icon>
        <p>{{ $t('sideBar.Warehouses') }}</p>
      </sidebar-link>
      <sidebar-link to="/warehouse-items">
        <md-icon>devices</md-icon>
        <p>{{ $t('sideBar.WarehouseItems') }}</p>
      </sidebar-link>
      <sidebar-link to="/reports">
        <md-icon>library_books</md-icon>
        <p>{{ $t('sideBar.Reports') }}</p>
      </sidebar-link>
      <sidebar-link to="/items">
        <md-icon>storage</md-icon>
        <p>{{ $t('sideBar.Items') }}</p>
      </sidebar-link>
      <sidebar-link to="/operations">
        <md-icon>autorenew</md-icon>
        <p>{{ $t('sideBar.Operations') }}</p>
      </sidebar-link>
      <!-- <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link> -->
    </side-bar>

    <div class="main-panel" :style="{ float: locale === 'en' ? 'right' : 'left' }">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      locale:localStorage.getItem('lang')
    };
  },
};
</script>
