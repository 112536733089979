<template>
  <div id="app" class="container">

    <v-dialog :value="dialog" @input="$emit('input')" max-width="590" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <div v-if="edit">
            <v-toolbar-title class="text-h6 white--text">{{ $t('labels.editWarehouse') }}</v-toolbar-title>
          </div>
          <div v-else>
            <v-toolbar-title class="text-h6 white--text">{{ $t('labels.newWarehouse') }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div class="align-items-center">
          <div>

            <v-card-text>
              <v-text-field :label="$t('warehouses.warehouseName')" v-model="warehouseName"></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-select v-model="branch" :label="$t('warehouses.branch')" :items="getBranches" item-text="name"
                item-value="id"  ></v-select>
            </v-card-text>

            <v-card-text>
              <v-select class="select" v-model="warehouseType" :label="$t('warehouses.type')" :items="types" item-text="name"
                item-value="id"></v-select>
            </v-card-text>

            <v-card-text>
              <v-textarea :label="$t('warehouses.description')" v-model="description"></v-textarea>
            </v-card-text>




          </div>


        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="edit">
            <v-btn color="blue darken-1" text  @click="submitEdit(item)">{{ $t('buttons.submit') }}</v-btn>
          </div>
          <div v-else>
            <v-btn color="blue darken-1" text  @click="submitAdd">{{ $t('buttons.submit') }}</v-btn>
          </div>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>
import { useBranchesStore } from "./../../store/BranchesStore";
import { useWarehouseStore } from "./../../store/WarehouseStore";

export default {
  props: {
    dialog: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      warehouseName: "",
      warehouseType: "",
      branch: "",
      description: "",
      branchStore: null,
      warehouseStore: null,
      lang:localStorage.getItem('lang'),
      types: [
        {
          name: "Maintenance",
          id: "1",
        },
        {
          name: "Normal",
          id: "0",
        },
      ],
    };
  },
  methods: {
    submitAdd() {
      const payload = {
        branch_id: this.branch,
        description: this.description,
        name: this.warehouseName,
        is_maintenance: this.warehouseType,
      };
      this.warehouseStore.createWarehouse(payload);
      this.$emit("input", false);
    },
    submitEdit(item) {
      const payload = {
        branch_id: this.branch,
        description: this.description,
        name: this.warehouseName,
        is_maintenance: this.warehouseType,
      };
      this.warehouseStore.editWarehouse(item.id, payload);
      this.$emit("input", false);
    },
    close() {
      this.$emit("input", false);
      this.$emit("edit", false);
    },
  },
  watch: {
    edit(val) {
      if (!val) {
        this.warehouseName = "",
          this.warehouseType = "",
          this.branch = "",
          this.description = "";
      }
    },
    item(val) {
      if (val) {
        this.warehouseType = val.isMaintenance;
        this.warehouseName = val.name;
        this.branch = val.branchId;
        this.description = val.description;
      }
    },
  },
  computed: {
    getBranches() {
      return this.branchStore.getBranches;
    },
  },
  created() {
    this.branchStore = useBranchesStore();
    this.warehouseStore = useWarehouseStore();
  },
};
</script>

<style></style>