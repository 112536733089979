<template>
  <div id="app" class="container">

    <v-dialog :value="dialog" @input="$emit('input')" max-width="590" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <div v-if="edit">
            <v-toolbar-title class="text-h6 white--text">{{ $t('labels.editItem') }}</v-toolbar-title>

          </div>
          <div v-else>
            <v-toolbar-title class="text-h6 white--text">{{ $t('labels.newItem') }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div class="align-items-center">
          <div>

            <v-card-text>
              <v-text-field :label="$t('labels.itemName')" v-model="name"></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field :label="$t('labels.itemNo')" v-model="itemNo"></v-text-field>
            </v-card-text>


            <v-card-text>
              <v-select class="select" v-model="factory" :label="$t('labels.factory')" :items="getFactories" item-text="name"
                item-value="id"></v-select>
            </v-card-text>

            <v-card-text>
              <v-text-field :label="$t('labels.model')" v-model="model"></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field :label="$t('labels.source')" v-model="source"></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field :label="$t('labels.cost')" v-model="cost"></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field :label="$t('labels.minQty')" v-model="minQty"></v-text-field>
            </v-card-text>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="edit">
            <v-btn color="blue darken-1" text @click="submitEdit(item)">{{ $t('buttons.submit') }}</v-btn>
          </div>
          <div v-else>
            <v-btn color="blue darken-1" text @click="submitAdd">{{ $t('buttons.submit') }}</v-btn>
          </div>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>
import { useFactoriesStore } from "./../../store/FactoriesStore";
import { useItemsStore } from "./../../store/ItemsStore";

export default {

  props: {
    dialog: {
      type: Boolean,
    },
    item: {
      type: Object
    },
    edit: {
      type: Boolean
    }
  },

  data() {
    return {
      name: "",
      itemNo: "",
      factory: "",
      model: "",
      source: "",
      cost: "",
      minQty: "",
      factoryStore: null,
      itemStore: null
    }
  },
  methods: {
    submitAdd() {
      const payload = {
        name: this.name,
        item_no: this.itemNo,
        factory_id: this.factory,
        model: this.model,
        source: this.source,
        cost: this.cost,
        min_qty: this.minQty
      }
      this.itemStore.createItem(payload)
      this.$emit('input', false);
      this.name = ""
      this.itemNo = ""
      this.factory = ""
      this.model = ""
      this.source = ""
      this.cost = ""
      this.minQty = ""
    },
    submitEdit(item) {

      const payload = {
        name: this.name,
        item_no: this.itemNo,
        factory_id: this.factory,
        model: this.model,
        source: this.source,
        cost: this.cost,
        min_qty: this.minQty
      }
      this.itemStore.editItem(item.id, payload)
      this.$emit('input', false);
    },
    close() {
      this.$emit('input', false);
      this.$emit('edit', false);
      this.name = ""
      this.itemNo = ""
      this.factory = ""
      this.model = ""
      this.source = ""
      this.cost = ""
      this.minQty = ""

    }
  },
  watch: {
    edit(val) {
      if (!val) {
        this.name = ""
        this.itemNo = ""
        this.factory = ""
        this.model = ""
        this.source = ""
        this.cost = ""
        this.minQty = ""
      }
    },
    item(val) {
      if (val) {
        this.name = val.name
        this.itemNo = val.itemNo
        this.factory = val.factoryId
        this.model = val.model
        this.source = val.source
        this.cost = val.cost
        this.minQty = val.minQty
      }
    }

  },
  computed: {
    getFactories() {
      return this.factoryStore.getFactories
    }
  },
  created() {
    this.factoryStore = useFactoriesStore()
    this.itemStore = useItemsStore()
    this.factoryStore.setFactories()

  }


}
</script>

<style></style>