import { defineStore } from "pinia";
import http from "../http";
export const useWarehouseStore = defineStore("warehousStore", {
  state: () => ({ 
    warehouses:[],
   }),
  getters: {
    getWarehouses(){
         return this.warehouses
    }
  },
  actions: {
   async setWarehouses() {
       await http.get("warehouses").then((response) => {
        if(response){
          this.warehouses = response.data.data
        }
          });
    },
   async createWarehouse(warehouse){
     await http.post("warehouses", warehouse).then((response) => {
        let data = response.data.data
        this.warehouses.push(data)
      });
    },
    async  editWarehouse(warehouseId,payload){
      await http.put(`warehouses/${warehouseId}`,payload).then((response)=>{
        const data = response.data
        var newArr = this.warehouses.map(obj => {
          if (data.id === obj.id) {
            return data; // Replace object if IDs match
          }
          return obj; // Keep the original object if IDs don't match
        });
        this.warehouses = newArr
      })
    },
    async deleteWarehouse(warehouseId) {
      await  http.delete(`warehouses/${warehouseId}`).then((response) => {
        let data = response
        if (data.status === 200) {
          this.warehouses = this.warehouses.filter(item => item.id !== warehouseId);
        }
      });
    },
  transfer(payload,th){
    http.post("operations",payload).then((response)=>{
   
    })
  },
 
  },
});