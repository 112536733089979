<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="translatedItems" width="auto">
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
                                    <v-text-field
                                        v-if="header.text !== 'Cost' && header.text !== 'الكلفة' && header.text !== 'Min Qty' && header.text !== 'الكمية الدنيا'"
                                        v-model="header.inputValue" hide-details></v-text-field>
                                </th>
                            </tr>
                            <tr>

                            </tr>
                        </thead>
                    </template>
                    <template #item="{ item }">
                        <tr>
                            <td>{{ item.name }}</td>
                            <td>{{ item.itemNo }}</td>
                            <td>{{ item.factoryName }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.source }}</td>
                            <td>{{ item.cost }}</td>
                            <td>{{ item.minQty }}</td>
                            <td>
                                <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
                            </td>
                            <td>
                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                            </td>
                        </tr>
                    </template> 
                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { useItemsStore } from "./../../store/ItemsStore";
export default {
    name: "DataTable",
    props: {

        edit: {
            type: Boolean,
        },
        item: {
            type: Object,
        },
        headers: {
            type: Array,
        },
    },
    data() {
        return {
            itemsStore: null,
        };
    },
    methods: {
        editItem(item) {
            this.$emit("item", item);
            this.$emit("edit", true);

        },
        deleteItem(item) {
            this.itemsStore.deleteItem(item.id);
        },
    },
    computed: {
        getItems() {
            return this.itemsStore.getItems;
        },
        translatedHeaders() {
            return this.$t(`itemsHeaders`);
        },
        translatedItems() {
            return this.getItems.filter((item) => {
                const name = item.name;
                const itemNo = item.itemNo
                const factory = item.factoryName
                const model = item.model
                const source = item.source
                return (

                    name.toLowerCase().includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
                    itemNo.toLowerCase().includes(this.translatedHeaders[1].inputValue.toLowerCase()) &&
                    factory.toLowerCase().includes(this.translatedHeaders[2].inputValue.toLowerCase()) &&
                    model.toLowerCase().includes(this.translatedHeaders[3].inputValue.toLowerCase()) &&
                    source
                        .toLowerCase().includes(this.translatedHeaders[4].inputValue.toLowerCase())

                );
            });
        },
    },
    mounted(){
        this.itemsStore.setItems()
    },
    created() {
        this.itemsStore = useItemsStore();
    },
};
</script>

<style scoped></style>