<template>

  <div class="linear-background">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 center login-logo">
          <img src="../../assets/img/white-logo.png" alt="">
        </div>
        <div class="col-6  login-col">
          <form class="d-flex align-items-center justify-content-center flex-column login-form w-100" action="">
            <h1 class="col-5"></h1>
            <div class="center my-2" style="width: 100%;position: relative;">
              <input v-model="form.email" class="my-2" type="email" placeholder="  Email " required />
            </div>

            <div class="center my-2" style="width: 100%;position: relative;">
              <input v-model="form.password" class=" login-password" :type="showPassword ? 'text' : 'password'"
                placeholder="  Password " minlength="6" required />
              <i class="fa-regular fa-eye-slash" @click="togglePasswordVisibility"></i>
            </div>

            <div class="center my-2" style="width: 100%;position: relative;">
              <button class="mt-4" type="submit" @click.prevent="submitLogin">Sign In</button>
            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useAuthStore } from "../../store/AuthStore";
export default {

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showPassword: false,
      message: {},
      authStore:null
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    submitLogin() {
      const axiosInstanceRemote = axios.create({
      });
      axiosInstanceRemote.post('https://auth.techcare-sy.com/api/login', this.form)
        .then(response => {
           
          localStorage.setItem('token', response.data.token);
          //localStorage.setItem('abilities', response.data.abilities);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          this.authStore.login(this.form)

        })
        .catch(error => {
          if (error.response.status === 422) {
            this.message.value = error.response.data.message;
          }
        })
        .finally(() => this.form.password = '');
    }
  },
  created(){
    this.authStore = useAuthStore();
  }
};
</script>

<style>
@import "../../assets/scss/style.css";
</style>