<template>
  <div id="app" class="container">
    <v-data-table :headers="translatedHeaders" :items="translatedItems">
      <template v-slot:header="{ props }">
        <thead>
          <tr>
            <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
              <v-text-field
                v-if="header.text !== 'Cost' && header.text !== 'الكلفة' && header.text !== 'minQty' && header.text !== 'الكمية الدنيا' && header.text !== 'Qty' && header.text !== 'الكمية'"
                v-model="header.inputValue" hide-details></v-text-field>
            </th>
          </tr>
          <tr>

          </tr>
        </thead>
      </template>
      <template #item="{ item }">

        <tr>
          <td>{{ item.warehouseName }}</td>
          <td>{{ item.itemNo }}</td>
          <td>{{ item.itemName }}</td>
          <td>{{ item.factoryName }}</td>
          <td>{{ item.model }}</td>
          <td>{{ item.source }}</td>
          <td>{{ item.cost }}</td>
          <td>{{ item.minQty }}</td>
          <td>{{ item.qty }}</td>

          <td>
            <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          </td>
          <td>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="button-container">
      <v-btn class="item-btn" @click="addNew()">{{ $t('buttons.addNew') }}</v-btn>
      <v-btn class="item-btn" @click="dialogImport = true">{{ $t('buttons.importExcel') }}</v-btn>
      <v-btn class="item-btn" @click="exportExcel">{{ $t('buttons.exportExcel') }}</v-btn>
      <v-btn class="item-btn" @click="openDialogTransfer()">{{ $t('buttons.transfer') }}</v-btn>
      <v-btn class="item-btn" @click="openDialogInquirey()">{{ $t('buttons.inquirey') }}</v-btn>
      <v-btn class="item-btn" @click="dialogExportation = true">{{ $t('buttons.exportation') }}</v-btn>
    </div>





    <v-dialog v-model="dialog" max-width="546" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.newItem') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer></v-spacer>


        <v-card-text>
          <v-select class="select" v-model="formData.warehouseId" :label="$t('labels.warehouseName')"
            :items="getWarehouses" item-text="name" item-value="id"></v-select>
        </v-card-text>

        <v-card-text>
          <v-select class="select" v-model="formData.itemId" :label="$t('labels.itemName')" :items="getItems"
            item-text="name" item-value="id"></v-select>
        </v-card-text>

        <v-card-text>
          <v-text-field v-model="formData.qty" type="number" min="0" :label="$t('labels.qty')"></v-text-field>
        </v-card-text>



        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="submitAdd">{{ $t('buttons.submit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog of Edit item -->
    <v-dialog v-model="dialogEdit" max-width="546" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">Edit Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEdit = false" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-text-field v-model="formData.qty" :label="$t('labels.qty')"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="submitEdit">{{ $t('buttons.submit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog fo Delete Alert -->
    <v-dialog v-model="deleteDialog" max-width="546" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">Delete Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="deleteDialog = false" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <h4> Are you sure you want to delete this item? </h4>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">Cancel</v-btn>

          <v-btn color="blue darken-1" text @click="submitDelete">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for import excel -->

    <v-dialog v-model="dialogImport" max-width="546" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.importExcel') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogImport = false" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>
        <v-card-text>
          <v-file-input @change="handleFileUpload" counter multiple show-size truncate-length="1"></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submitImport">{{ $t('buttons.submit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for Transfer -->
    <v-dialog v-model="dialogTransfer" max-width="800" height="632" persistent>
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.transferItem') }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="closeTransfer" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div>
          <div class="mr-4">
            <v-card-text>
              <v-select v-on:change="handleSourceWarehouseChange($event)" class="select"
                v-model="transferVariables.sourceWarehouse" :label="$t('labels.sourceWarehouse')" :items="getWarehouses"
                item-text="name" item-value="id"></v-select>
            </v-card-text>

            <v-card-text>
              <div>
                <v-select class="select" v-model="transferVariables.targetWarehouse"
                  :label="$t('labels.targetWarehouse')" :items="getWarehouses" item-text="name"
                  item-value="id"></v-select>
              </div>
            </v-card-text>

            <v-card-text>
              <v-select class="select" v-model="transferVariables.selectedItem"
                @change="handleTransferItemChange($event, isTransfer = true)" :label="$t('labels.selectItem')"
                :items="getWarehouseItems" item-text="name" item-value="id" clearable return-object></v-select>
            </v-card-text>

            <v-card-text>
              <!-- <v-text-field v-model="transferVariables.transferQty" label="Qty"></v-text-field> -->
              <v-data-table :headers="$t('transferHeaders')" :items="transferItems" class="elevation-1 hover">
                <template #item="{ item }">
                  <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.itemNo }}</td>
                    <td>{{ item.factoryName }}</td>
                    <td>{{ item.model }}</td>
                    <td>{{ item.source }}</td>
                    <td>{{ item.cost }}</td>
                    <td>{{ item.minQty }}</td>
                    <td>{{ item.qty }}</td>

                    <v-text-field v-model="item.refillQty" type="number" min="0" step="1"
                      :rules="[compareValue(item)]"></v-text-field>
                    <td>
                      <v-icon small @click="deleteItemFromArray(item.id, 'transfer')">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </v-card-text>

          </div>

        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submitTransfer">{{ $t('buttons.submit')

            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for Inquirey -->

    <v-dialog v-model="dialogInquirey" max-width="800" height="500" persistent>
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.refillItems') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogQuirey()" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select v-on:change="handleInquireyWarehouseChange($event)" class="select" v-model="selectedWarehouse"
            :label="$t('labels.selectWarehouse')" :items="getWarehouses" item-text="name" item-value="id"></v-select>
        </v-card-text>
        <v-card-text>
          <v-data-table :headers="$t('refillHeaders')" :items="getRefillItems" class="elevation-1 hover">
            <template #item="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.itemNo }}</td>
                <td>{{ item.factoryName }}</td>
                <td>{{ item.model }}</td>
                <td>{{ item.source }}</td>
                <td>{{ item.cost }}</td>
                <td>{{ item.minQty }}</td>
                <td>{{ item.qty }}</td>

                <v-text-field v-model="item.refillQty" type="number" min="0" step="1"></v-text-field>
              </tr>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- Add your custom filter inputs here -->
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogQuirey">
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitRefill">
            {{ $t('buttons.submit') }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Dialog for exportation -->

    <v-dialog v-model="dialogExportation" max-width="800" height="632" persistent>
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">
          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.exportationItems') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeExportation" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div class="align-items-center">
  <div>
    <v-card-text>
      <v-select v-on:change="handleSourceWarehouseChange($event)" class="select"
        v-model="exportationVariables.warehouse" :label="$t('labels.sourceWarehouse')" :items="getWarehouses"
        item-text="name" item-value="id"></v-select>
    </v-card-text>
    
  </div>
  <v-card-text>
    <v-select @change="showFields($event)" class="select" v-model="exportationVariables.exportationType"
      :label="$t('labels.type')" :items="exportationTypes" item-text="label" item-value="value"></v-select>
  </v-card-text>
  <div>
    <v-card-text v-if="exportationVariables.exportationType === 'sell'">
      <v-text-field :label="$t('exportationTypes.seller')" v-model="exportationVariables.seller"
        v-show="sellerShow"></v-text-field>
      <v-text-field :label="$t('exportationTypes.buyer')" v-model="exportationVariables.buyer"
        v-show="buyerShow"></v-text-field>
    </v-card-text>
    <v-card-text v-else-if="exportationVariables.exportationType === 'other'">
      <v-text-field :label="$t('exportationTypes.seller')" v-model="exportationVariables.seller"
        v-show="sellerShow"></v-text-field>
      <v-text-field :label="$t('exportationTypes.buyer')" v-model="exportationVariables.buyer"
        v-show="buyerShow"></v-text-field>
      <v-textarea :label="$t('exportationTypes.note')" v-model="exportationVariables.note"
        v-show="noteShow"></v-textarea>
    </v-card-text>
  </div>
  <v-card-text>
      <v-select class="select" v-model="exportationVariables.itemSelected" :label="$t('labels.selectItem')"
        @change="handleTransferItemChange($event, isTransfer = false)" :items="getWarehouseItems"
        item-text="name" item-value="id" searchable clearable return-object></v-select>
    </v-card-text>
 
</div>
        <v-card-text>
          <!-- <v-text-field v-model="transferVariables.transferQty" label="Qty"></v-text-field> -->
          <v-data-table :headers="$t('exportationHeaders')" :items="exportItems" class="elevation-1 hover">
            <template #item="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.itemNo }}</td>
                <td>{{ item.factoryName }}</td>
                <td>{{ item.model }}</td>
                <td>{{ item.source }}</td>
                <td>{{ item.cost }}</td>
                <td>{{ item.minQty }}</td>
                <td>{{ item.qty }}</td>

                <v-text-field v-model="item.refillQty" type="number" min="0" step="1"
                  :rules="[compareValue(item)]"></v-text-field>
                <td>
                  <v-icon small @click="deleteItemFromArray(item.id, 'export')">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <!-- Add your custom filter inputs here -->
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submitExportation">{{ $t('buttons.submit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { useItemsStore } from "./../store/ItemsStore";
import { useFactoriesStore } from "./../store/FactoriesStore";
import { useWarehouseStore } from "./../store/WarehouseStore";
import { useWarehouseItemsStore } from "./../store/WarehouseItemsStore";

export default {
  data() {
    return {
      deleteDialog: false,
      itemId: null,
      itemToDelete: null,
      number: 0,
      excelFile: null,
      itemsStore: null,
      factoryStore: null,
      warehouseStore: null,
      warehouseItemsStore: null,
      transferVariables: {
        sourceWarehouse: "",
        targetWarehouse: "",
        selectedItem: "",
        transferQty: "",
      },
      selectedWarehouse: "",
      operationType: "",
      exportationVariables: {
        warehouse: "",
        itemSelected: "",
        exportationQty: "",
        exportationType: "",
        seller: "",
        buyer: "",
        note: "",
      },
      sellerShow: false,

      buyerShow: false,
      noteShow: false,

      exportationTypes: [
        {
          label: this.$t("exportationTypes.sell"),
          value: "sell",
        },
        {
          label: this.$t("exportationTypes.waste"),
          value: "waste",
        },
        {
          label: this.$t("exportationTypes.other"),
          value: "other",
        },
      ],

      formData: {
        warehouseId: null,
        itemId: null,
        qty: 0,
      },

      dialog: false,
      dialogEdit: false,
      dialogImport: false,
      dialogExport: false,
      dialogTransfer: false,
      dialogInquirey: false,
      dialogExportation: false,

      searchName: "",
      searchItem_no: "",
      searchFactory: "",
      searchModel: "",
      searchSource: "",
      searchCost: "",
      searchMinQty: "",
      headers: [
        {
          text: "Warehouse Name",
          value: "warehouse_name",
          divider: true,
          inputValue: "",
        },
        { text: "Item No", value: "itemNo", divider: true, inputValue: "" },
        { text: "Item Name", value: "itemName", divider: true, inputValue: "" },
        { text: "Factory", value: "factory", divider: true, inputValue: "" },
        { text: "Model", value: "model", divider: true, inputValue: "" },
        { text: "Source", value: "source", divider: true, inputValue: "" },
        { text: "Cost", value: "cost", divider: true, inputValue: "" },
        { text: "minQty", value: "MINQty", divider: true, inputValue: "" },
        { text: "Qty", value: "Qty", inputValue: "" },
      ],

      transferItems: [],
      exportItems: [],
      isTransfer: false,
      rules: [
        (value) => {
          if (value > 3) return true;

          return "You must enter a first name.";
        },
      ],
    };
  },
  created() {
    this.itemsStore = useItemsStore();
    // this.factoryStore = useFactoriesStore();
    // this.factoryStore.setFactories();
    this.warehouseStore = useWarehouseStore();
    this.warehouseItemsStore = useWarehouseItemsStore();
    this.warehouseItemsStore.setWarehouseItems();
    // this.itemsStore.setItems();
    this.warehouseStore.setWarehouses();
  },

  methods: {
    compareValue(row) {
      return (value) => {
        if (value > row.qty) {
          return "Value more than current qty";
        } else {
          return true;
        }
      };
    },
    addNew() {
      this.itemsStore.setItems();
      this.dialog = true;
      this.formData = {};
    },
    showFields(event) {
      if (event == "other") {
        this.noteShow = true;
        this.sellerShow = false;
        this.buyerShow = false;
      } else if (event == "sell") {
        this.sellerShow = true;
        this.buyerShow = true;
        this.noteShow = false;
      } else {
        this.noteShow = false;
        this.sellerShow = false;
        this.buyerShow = false;
      }
    },
    closeExportation() {
      this.dialogExportation = false;
      this.noteShow = false;
      this.sellerShow = false;
      this.buyerShow = false;
      this.exportationVariables = {};
    },
    closeTransfer() {
      this.dialogTransfer = false;
      this.transferVariables = {};
      this.transferItems = [];
      this.itemsStore.setWarehouseEmpty();
    },
    openDialogTransfer() {
      this.dialogTransfer = true;
      this.operationType = "tran";
      this.warehouseStore.setWarehouses();
    },
    handleFileUpload(event) {
      this.excelFile = event;
    },
    submitAdd() {
      // const itemsStore = useItemsStore();
      const payload = {
        warehouse_id: this.formData.warehouseId,
        item_id: this.formData.itemId,
        qty: this.formData.qty,
      };
      this.warehouseItemsStore.createWarehouseItem(payload);
      this.formData = {};
      this.dialog = false;
    },
    submitEdit() {
      this.dialogEdit = false;
      const payload = this.formData;

      this.warehouseItemsStore.editWarehouseItem(this.itemId, payload);
    },
    submitDelete() {
      this.warehouseItemsStore.deleteWarehouseItem(this.itemToDelete.id);
      this.deleteDialog = false;
    },
    submitImport() {
      this.warehouseItemsStore.importExcel(this.excelFile, this);
      this.dialogImport = false;
    },
    submitTransfer() {
      let transferArray = [];
      const payload = {
        type: this.operationType,
        transferArray: transferArray,
        src_warehouse_id: this.transferVariables.sourceWarehouse,
        dist_warehouse_id: this.transferVariables.targetWarehouse,
        item_id: this.transferVariables.selectedItem,
        qty: 0,
      };
      this.transferItems.map((item) => {
        if (item.refillQty !== undefined) {
          if (item.refillQty !== "0") {
            transferArray.push(item);
          }
        }
        // Assuming you have a number input for the refill quantity
      });

      this.warehouseStore.transfer(payload, this);
      this.dialogTransfer = false;
    },
    submitRefill() {
      let refillArray = [];
      this.operationType = "inq";
      // Collect the data from the data table
      this.getRefillItems.map((item) => {
        if (item.refillQty !== 0) {
          refillArray.push(item);
        }
        // Assuming you have a number input for the refill quantity
      });
      const payload = {
        refillArray: refillArray,
        type: this.operationType,
        warehouse_id: this.selectedWarehouse,
        qty: 0,
      };
      this.itemsStore.createRefillOperation(payload);
      this.closeDialogQuirey();
    },
    submitExportation() {
      this.dialogExportation = false;
      this.operationType = "exp";
      const payload = {
        type: this.operationType,
        warehouse_id: this.exportationVariables.warehouse,
        item_id: this.exportationVariables.itemSelected,
        seller: this.exportationVariables.seller,
        buyer: this.exportationVariables.buyer,
        ex_type: this.exportationVariables.exportationType,
        note: this.exportationVariables.note,
        qty: 0,
        exportArray: this.exportItems,
      };
      this.warehouseStore.transfer(payload, this);
      this.exportationVariables = {};
    },
    exportExcel() {
      this.itemsStore.exportExcel();
    },
    editItem(item) {
      // Handle logic for editing the item
      this.dialogEdit = true;
      this.itemId = item.id;
      this.formData.warehouseName = item.warehouseName;
      this.formData.qty = item.qty;
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    deleteItemFromArray(itemId, caller) {
      let itemsArray = [];
      if (caller == "transfer") {
        itemsArray = this.transferItems.filter((item) => item.id !== itemId);
        this.transferItems = itemsArray;
      } else {
        itemsArray = this.exportItems.filter((item) => item.id !== itemId);
        this.exportItems = itemsArray;
      }
    },
    handleSourceWarehouseChange(event) {
      this.itemsStore.itemsByWarehouse(event);
    },
    openDialogInquirey() {
      this.dialogInquirey = true;
    },
    handleInquireyWarehouseChange(event) {
      this.itemsStore.setRefillItems(event);
    },
    handleTransferItemChange(event, isTransfer) {
      if (isTransfer) {
        const foundObject = this.transferItems.find(
          (obj) => obj.id === event.id && obj.name === event.name
        );
        if (!foundObject) {
          this.transferItems.push(event);
        }
      } else {
        const foundObject = this.exportItems.find(
          (obj) => obj.id === event.id && obj.name === event.name
        );
        if (!foundObject) {
          this.exportItems.push(event);
        }
      }
    },
    closeDialogQuirey() {
      this.dialogInquirey = false;
      this.selectedWarehouse = "";
      this.itemsStore.emptyRefillItems();
    },
  },
  computed: {
    translatedItems() {
      return this.getAllWarehouseItems.filter((item) => {
        const name = item.warehouseName;
        const itemNo = item.itemNo;
        const itemName = item.itemName;
        const factoryName = item.factoryName;
        const model = item.model;
        const source = item.source;
        return (
          (name ? name : "")
            .toLowerCase()
            .includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
          (itemNo ? itemNo : "")
            .toString()
            .includes(this.translatedHeaders[1].inputValue) &&
          (itemName ? itemName : "")
            .toLowerCase()
            .includes(this.translatedHeaders[2].inputValue.toLowerCase()) &&
          (factoryName ? factoryName : "")
            .toLowerCase()
            .includes(this.translatedHeaders[3].inputValue.toLowerCase()) &&
          (model ? model : "")
            .toString()
            .toLowerCase()
            .includes(this.translatedHeaders[4].inputValue.toLowerCase()) &&
          (source ? source : "")
            .toLowerCase()
            .includes(this.translatedHeaders[5].inputValue.toLowerCase())
        );
      });
    },
    translatedHeaders() {
      return this.$t(`warehouseItemsHeaders`);
    },
    getItems() {
      return this.itemsStore.getItems;
    },
    getFactories() {
      return this.factoryStore.getFactories;
    },
    getWarehouses() {
      return this.warehouseStore.getWarehouses;
    },
    getWarehouseItems() {
      return this.itemsStore.getWarehouseItems;
    },
    getRefillItems() {
      return this.itemsStore.getRefillItems;
    },
    getAllWarehouseItems() {
      return this.warehouseItemsStore.getWarehouseItems;
    },
  },
};
</script>

<style scoped>
.item-btn {
  background-color: #2a8862 !important;
  color: white !important;
  margin-top: 46px;
  /* Adjust margin top as needed */
  margin-left: 20px;
  /* Adjust margin left as needed */
  height: 44px;
  border-radius: 10px;
  width: 145px;
  margin-right: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* Add this line */
}

.headline {
  width: 300px;
  height: 48px;
  position: absolute;

  left: 30%;
  transform: translate(-50%, -50%);
}

.dialog {
  height: 632px !important;
}

.close-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 6px;
  left: 10px;
  gap: 0px;
  border: 2px 0px 0px 0px;
  opacity: 0px;
  margin-left: 511px;
}
</style>
