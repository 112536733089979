<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title"> {{ $t(`sideBar.${$route.name}`) }} </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse col-8">
          <div>
            <!-- <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
            >
              <label>Search...</label>
            </md-autocomplete> -->
          </div>

          <v-select
            app
            v-model="lang"
            :items="options"
            item-text="label"
            item-value="value"
            :label="$t('selectLanguage')" 
            @change="handleChange($event)"
          >
          </v-select>

          <md-list>
            <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dssashboard</p>
            </md-list-item>

            <!-- <md-list-item href="#/notifications" class="dropdown">
              <drop-down>
                <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons">notifications</i>
                  <span class="notification">5</span>
                  <p class="hidden-lg hidden-md">Notifications</p>
                </a>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a href="#">Mike John responded to your email</a></li>
                  <li><a href="#">You have 5 new tasks</a></li>
                  <li><a href="#">You're now friend with Andrew</a></li>
                  <li><a href="#">Another Notification</a></li>
                  <li><a href="#">Another One</a></li>
                </ul>
              </drop-down>
            </md-list-item> -->

            <!-- <li class="md-list-item">
              <a
                href="#/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li> -->

            <div>
    <md-list-item  @click="showLogoutCard = !showLogoutCard">
      <i class="material-icons">person</i>
      <p class="hidden-lg hidden-md">Profile</p>
    </md-list-item>

    <div class="logout-card" v-show="showLogoutCard">
      <v-card>
        <v-card-text>
        </v-card-text>
        

          <v-card-actions>
            <v-btn class="logout-button" color="blue darken-1" text @click="logout">
              <v-icon left>mdi-logout</v-icon>
              {{ $t('buttons.logout') }}
            </v-btn>
          </v-card-actions>
      </v-card>
    </div>
  </div>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "en";
    return {
      showLogoutCard: false,
      lang: lang,
      selectedOption: null,
      options: [
        { label: "Arabic", value: "ar" },
        { label: "English", value: "en" },
      ],
      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    handleChange(event) {
      localStorage.setItem("lang", event);
      
      window.location.reload();
      // const elems = document.getElementsByTagName("*");
      // for (let elem of elems) {
      //   console.log("elems");
      //   elem.style.direction = "rtl";
      // }
    },
    logout() {
      localStorage.removeItem("token");
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-card {
  position: relative;
}
.logout-button {
  text-transform: initial;
}
.logout-card {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
}

</style>


